<template>
  <div>
    <v-card id="receipt-list">
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center pb-5">
          <!-- create receipt -->
          <v-btn
            v-if="can('write', 'Receipt')"
            color="primary"
            class="me-3 receipt-button"
            :disabled="createReceiptLimit"
            :to="{ name: 'apps-receipt-add' }"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>{{ t('Create Receipt') }}</span>
          </v-btn>
        </div>

        <div
          v-if="createReceiptLimit"
          class="create-receipt-hint"
        >
          <small>{{ t('receipt-create-limit') }}</small>
        </div>

        <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>

        <div class="row text-right pb-sm-6 pb-5">
          <div class="col-sm-3 pa-2 col-6">
            <!-- filter account -->
            <v-combobox
              v-model="accountFilters"
              :items="accounts"
              item-text="name"
              single-line
              multiple
              outlined
              dense
              hide-details
              clearable
              :placeholder="t('Select Account')"
              :class="{ 'receipt-list-account': $vuetify.breakpoint.smAndUp }"
            ></v-combobox>
          </div>

          <div class="col-sm-3 pa-2 col-6">
            <!-- filter broker -->
            <v-combobox
              v-model="brokerFilters"
              :items="brokers"
              item-text="name"
              single-line
              multiple
              outlined
              dense
              hide-details
              clearable
              :placeholder="t('Select Broker')"
              :class="{ 'receipt-list-account': $vuetify.breakpoint.smAndUp }"
            ></v-combobox>
          </div>

          <div class="col-sm-3 pa-2 col-6">
            <!-- filter corporate -->
            <v-combobox
              v-model="corporateFilters"
              :items="corporates"
              item-text="name"
              single-line
              multiple
              outlined
              dense
              hide-details
              clearable
              :placeholder="t('Select Corporate')"
              :class="{ 'receipt-list-account': $vuetify.breakpoint.smAndUp }"
            ></v-combobox>
          </div>

          <div class="col-6 col-sm-3 pa-2">
            <v-autocomplete
              v-model="toggleSelected"
              :items="branchOptions"
              item-text="name"
              item-value="value"
              single-line
              outlined
              dense
              hide-details
              clearable
              :placeholder="t('Select Branch Status')"
              @change="activeToggleChange()"
            ></v-autocomplete>
          </div>

          <div class="col-6 col-sm-3 pa-2">
            <!-- filter branch -->
            <v-combobox
              v-model="branchFilters"
              :items="branches"
              item-text="name"
              single-line
              multiple
              outlined
              dense
              hide-details
              clearable
              :placeholder="t('Select Branch')"
              :class="{ 'receipt-list-branch': $vuetify.breakpoint.smAndUp }"
            ></v-combobox>
          </div>

          <div class="col-sm-3 col-12 pa-2">
            <!-- filter insurance -->
            <v-combobox
              v-model="insuranceFilters"
              :items="insurances"
              item-text="name"
              single-line
              multiple
              outlined
              dense
              hide-details
              clearable
              :placeholder="t('Select Insurances')"
              :class="{ 'receipt-list-insurances': $vuetify.breakpoint.smAndUp }"
            ></v-combobox>
          </div>

          <div class="col-sm-3 col-12 pa-2">
            <!-- filter insurance category -->
            <v-combobox
              v-model="insuranceCategoryFilters"
              :items="insuranceCategoryOptions"
              item-text="name"
              single-line
              multiple
              outlined
              dense
              hide-details
              clearable
              :placeholder="t('Select Insurance Categories')"
              :class="{ 'receipt-list-insurances': $vuetify.breakpoint.smAndUp }"
            ></v-combobox>
          </div>
        </div>
      </v-card-text>

      <v-card-text class="row pb-0 pr-0">
        <div class="col-sm-2 col-12 pb-sm-8 pb-1 pl-2 pr-1">
          <!-- Date range -->
          <v-menu
            v-model="isDateMenuOpen"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :append-icon="icons.mdiCalendar"
                :value="dateRangeText"
                readonly
                dense
                outlined
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateRange"
              color="primary"
              :first-day-of-week="1"
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                block
                @click="dateRangeChange()"
              >
                {{ t('Set Dates') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>

        <div class="col-sm-2 offset-sm-2 px-2 px-sm-0 col-12">
          <!-- filter bank payment -->
          <v-select
            v-model="paymentSelected"
            :items="paymentFilters"
            item-value="id"
            item-text="name"
            dense
            outlined
            clearable
            hide-details="auto"
            :placeholder="t('Select Payment Method')"
          ></v-select>
        </div>

        <div class="d-flex align-center col-sm-4 col-12 pb-sm-8 pb-1 pr-1">
          <!-- search receipt -->
          <v-tooltip
            :left="$vuetify.breakpoint.smAndUp"
            :top="$vuetify.breakpoint.xsOnly"
            :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
            :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
            :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
            max-width="250"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                size="30"
                class="mr-3 hover-pointer"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="25">
                  {{ icons.mdiAlertCircleOutline }}
                </v-icon>
              </v-avatar>
            </template>
            <h3 class="my-2">
              {{ t('How to use the search bar') }}
            </h3>
            <p class="mb-1">
              {{ t('You can search using') }}:
            </p>
            <ul class="mb-2">
              <li>
                {{ t('#Receipt No.') }}
              </li>
              <li>
                {{ t('Insurance Policy No.') }}
              </li>
              <li>
                {{ t('Car Registration No.') }}
              </li>
              <li>
                {{ t('Customer Name') }}
              </li>
            </ul>
          </v-tooltip>
          <v-text-field
            v-model="searchText"
            :append-icon="icons.mdiMagnify"
            single-line
            dense
            outlined
            hide-details
            :placeholder="t('Search Receipt')"
            :class="{ 'receipt-list-search': $vuetify.breakpoint.mdAndUp }"
          ></v-text-field>
        </div>
        <div class="d-flex align-center col-sm-2 col-12 pl-3 pb-sm-8 pb-6 pl-0 pr-1">
          <v-btn
            block
            color="primary"
            @click="searchReceipts"
          >
            <v-icon
              class="pr-2"
              size="30"
            >
              {{ icons.mdiMagnify }}
            </v-icon>
            Search
          </v-btn>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="receipt-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="pageChange()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedReceipts"
        :headers="can('write', 'ReportActions') ? tableColumns : tableClerkColumns"
        :items="receipts"
        :options.sync="options"
        :items-per-page="100"
        :loading="loading"
        mobile-breakpoint="0"
        hide-default-footer
        show-select
        :disable-sort="true"
        class="text-no-wrap"
        @item-selected="selectedChange($event)"
        @toggle-select-all="toggleAll($event)"
      >
        <!-- Actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center">
            <!-- Edit -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  :to="{
                    name: (can('write', 'ReportActions') ? 'apps-receipt-edit' : 'apps-receipt-req'),
                    params: { id: item.id }
                  }"
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <!-- Void -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="voidableReceipt = item; isVoidDialogVisible = true"
                >
                  <v-icon size="18">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Void</span>
            </v-tooltip>

            <!-- View Insurance Policy -->
            <v-tooltip
              v-if="item.insurance_policy_url !== 'NULL'"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  :href="item.insurance_policy_url"
                  target="_blank"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>View insurance policy</span>
            </v-tooltip>
            <v-tooltip
              v-else
              bottom
            >
              <template #activator="{ on }">
                <div v-on="on">
                  <v-btn
                    icon
                    small
                    disabled
                  >
                    <v-icon size="18">
                      {{ icons.mdiHelpCircleOutline }}
                    </v-icon>
                  </v-btn>
                </div>
              </template>
              <span>No insurance policy attached</span>
            </v-tooltip>
          </div>
        </template>

        <!-- Receipt Number -->
        <template #[`item.receipt_number`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'apps-receipt-preview', params: { id: item.id } }"
          >
            {{ item.receipt_number }}
          </router-link>
        </template>

        <!-- After Dicsount -->
        <template
          v-if="can('write', 'AfterDiscount')"
          #[`item.after_discount`]="{item}"
        >
          <div class="d-flex align-center justify-center">
            <v-text-field
              v-model="item.after_discount"
              :reverse="true"
              min="0"
              type="number"
              single-line
              dense
              outlined
              hide-details
              suffix="$"
              class="receipt-payment my-3"
            ></v-text-field>
            <v-btn
              icon
              small
              class="ml-2"
              @click="valueChange(item.id, item.after_discount, 'after_discount')"
            >
              <v-icon size="18">
                {{ icons.mdiContentSave }}
              </v-icon>
            </v-btn>
          </div>
        </template>

        <!-- Net Payment -->
        <template #[`item.net_payment`]="{item}">
          <div class="d-flex align-center justify-center">
            <v-text-field
              v-model="item.net_payment"
              :rules="[value => checkNetPayment(item.net_payment, item.after_discount)]"
              :disabled="item.net_payment_lock"
              :reverse="true"
              min="0"
              type="number"
              single-line
              dense
              outlined
              hide-details="auto"
              suffix="$"
              class="receipt-payment my-3"
            ></v-text-field>
            <v-btn
              icon
              small
              class="ml-2"
              :disabled="!checkNetPayment(item.net_payment, item.after_discount) || item.net_payment_lock"
              @click="valueChange(item.id, item.net_payment, 'net_payment')"
            >
              <v-icon size="18">
                {{ icons.mdiContentSave }}
              </v-icon>
            </v-btn>
          </div>
        </template>

        <!-- Payment Screenshot -->
        <template #[`item.payment_method`]="{item}">
          <div>
            {{ item.payment_method }}
            <v-tooltip
              v-if="item.payment_screenshot_url !== 'NULL'"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="isScreenshotDialogVisible = true; receiptScreenshot = item"
                >
                  <v-icon size="18">
                    {{ icons.mdiPaperclip }}
                  </v-icon>
                </v-btn>
              </template>
              <span>View payment screenshot</span>
            </v-tooltip>
          </div>
        </template>

        <!-- Invoice Number -->
        <template #[`item.invoice_number`]="{item}">
          <router-link
            v-if="item.invoice_number !== 'NULL'"
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'apps-invoice-preview', params: { id: item.invoice_id } }"
          >
            {{ item.invoice_number }}
          </router-link>
          <p
            v-else
            class="mb-0"
          >
            NULL
          </p>
        </template>
      </v-data-table>

      <!-- Payment Screenshot dialog -->
      <v-dialog
        v-model="isScreenshotDialogVisible"
        max-width="1000"
      >
        <v-card v-if="receiptScreenshot">
          <v-card-title class="d-flex align-center py-3">
            <v-row>
              <v-col
                v-if="receiptScreenshot.issued_at"
                cols="12"
                sm="8"
              >
                {{ receiptScreenshot.receipt_number }}_{{ receiptScreenshot.issued_at.split('/').join('-') }}_{{ receiptScreenshot.payment_screenshot_name }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col
                cols="12"
                sm="4"
                class="text-right"
              >
                <v-btn
                  icon
                  small
                  class="ml-5"
                  @click="downloadScreenshot(receiptScreenshot)"
                >
                  <v-icon size="22">
                    {{ icons.mdiDownload }}
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  class="ml-5"
                  :href="receiptScreenshot.payment_screenshot_url"
                  target="_blank"
                >
                  <v-icon size="22">
                    {{ icons.mdiOpenInNew }}
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  class="ml-5"
                  @click="isScreenshotDialogVisible = false; receiptScreenshot = {}"
                >
                  <v-icon size="22">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <div v-if="receiptScreenshot.payment_screenshot_filetype === 'pdf'">
            <iframe
              class="payment-screenshot-viewer"
              :src="receiptScreenshot.payment_screenshot_url"
            ></iframe>
          </div>
          <div v-else>
            <v-img
              width="100%"
              :src="receiptScreenshot.payment_screenshot_url"
            ></v-img>
          </div>
        </v-card>
      </v-dialog>

      <!-- Void dialog -->
      <v-dialog
        v-model="isVoidDialogVisible"
        width="500"
      >
        <v-card>
          <v-card-title class="d-flex align-center mv-4">
            <div
              v-if="!can('write', 'ReportActions')"
              class="pr-1"
            >
              Request
            </div>
            Void {{ voidableReceipt.receipt_number }}?
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isVoidDialogVisible = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text v-if="can('write', 'ReportActions')">
            This receipt will be voided. It won't be taken into any calculations.
          </v-card-text>

          <v-card-text v-else>
            <v-textarea
              v-model="newComment"
              dense
              outlined
              hide-details="auto"
              placeholder="Leave your comment here (optional)"
              :disabled="loading"
              class="mb-4"
            ></v-textarea>
            This action will create a void request and this receipt will be voided after approved by auditor.
          </v-card-text>

          <v-card-actions>
            <v-btn
              v-if="can('write', 'ReportActions')"
              color="error"
              block
              :loading="voidLoading"
              :disabled="voidLoading"
              class="mt-3"
              @click="voidReceipt()"
            >
              Yes, remove
            </v-btn>

            <v-btn
              v-else
              color="error"
              block
              :loading="voidLoading"
              :disabled="voidLoading"
              class="mt-3"
              @click="createRequest()"
            >
              Yes, send request
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="receipt-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="pageChange()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="text-right">
      <v-btn
        v-if="pdfCount > 0"
        color="primary"
        dark
        :block="$vuetify.breakpoint.xsOnly"
        :loading="loadingDownload"
        :disabled="loadingDownload"
        class="receipt-button mt-5 mr-2"
        :class="{ 'loading-color': loadingDownload && !$vuetify.theme.dark }"
        @click="downloadPolicies()"
      >
        <span>Download Policies</span>
      </v-btn>
      <v-menu
        transition="slide-x-transition"
        offset-y
      >
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            :block="$vuetify.breakpoint.xsOnly"
            :loading="loadingPrint"
            :disabled="loadingPrint"
            class="receipt-button mt-5"
            :class="{ 'loading-color': loadingPrint && !$vuetify.theme.dark }"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              v-if="!loadingPrint"
              size="18"
              class="me-2"
            >
              {{ icons.mdiPrinter }}
            </v-icon>
            <span>{{ t('Print Report') }}</span>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            link
            @click="brokerFilters.length > 0 || corporateFilters.length > 0 ? printReport('broker_corporate_report') : printReport()"
          >
            <v-list-item-title>{{ t('Print full report') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="brokerFilters.length > 0 || corporateFilters.length > 0 ? printReport('non_motor_and_broker_corporate_report') : printReport('non_motor_report')"
          >
            <v-list-item-title>{{ t('Print non-motor report') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <v-card
      v-if="can('read', 'ReportSummaries')"
      class="my-5"
    >
      <v-card-title class="align-start">
        <span :class="{ 'text-sm': $vuetify.breakpoint.xsOnly }">{{ t('Summary for') }} {{ dateRangeText }}</span>
      </v-card-title>

      <v-card-text>
        <v-row class="py-3">
          <v-col
            cols="12"
            sm="6"
            lg="3"
            class="px-5 py-0"
            :class="{
              'dark-divider': $vuetify.theme.dark && $vuetify.breakpoint.mdAndUp,
              'light-divider': !$vuetify.theme.dark && $vuetify.breakpoint.mdAndUp
            }"
          >
            <Breakdowns
              field="After Discount"
              :summary="receiptsSummary.after_discount_summary"
              img="wallet"
              color="info"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="3"
            class="px-5 py-0"
            :class="{
              'dark-divider': $vuetify.theme.dark && $vuetify.breakpoint.mdAndUp,
              'light-divider': !$vuetify.theme.dark && $vuetify.breakpoint.mdAndUp
            }"
          >
            <Breakdowns
              field="Insurance Premium"
              :summary="receiptsSummary.insurance_premium_summary"
              img="credit-card"
              color="success"
            />

            <v-divider v-if="$vuetify.breakpoint.mdAndUp"></v-divider>

            <Breakdowns
              field="Net Payment"
              :summary="receiptsSummary.net_payment_summary"
              img="paypal"
              color="error"
            />

            <v-divider v-if="$vuetify.breakpoint.mdAndUp"></v-divider>

            <Breakdowns
              field="Voucher Discount"
              :summary="{ total: receiptsSummary.voucher_discount_summary.total }"
              img="wallet-2"
              color="secondary"
            />

            <v-divider v-if="$vuetify.breakpoint.mdAndUp"></v-divider>

            <Breakdowns
              field="Profit"
              :summary="receiptsSummary.profit_summary"
              img="arrow-growth"
              color="primary"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="3"
            class="px-5 py-0"
            :class="{
              'dark-divider': $vuetify.theme.dark && $vuetify.breakpoint.mdAndUp,
              'light-divider': !$vuetify.theme.dark && $vuetify.breakpoint.mdAndUp
            }"
          >
            <Breakdowns
              field="Additional Fees"
              :summary="receiptsSummary.additional_fees_summary"
              img="atm-card"
              color="warning"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="3"
            class="px-5 py-0"
          >
            <Breakdowns
              field="Road Tax"
              :summary="receiptsSummary.road_tax_summary"
              img="road-tax"
              color="accent"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <template v-for="summary in insuranceSummaryOptions">
      <v-card
        :key="summary.id"
        class="my-5"
      >
        <v-card-title class="align-start">
          <span :class="{ 'text-sm': $vuetify.breakpoint.xsOnly }">{{ t('Summary for') }} {{ t(summary.title) }}</span>
        </v-card-title>

        <v-card-text>
          <v-row class="py-3">
            <v-col
              v-for="(insurance, index) in receiptsSummary[summary.key].insurance_summary"
              :key="index"
              cols="12"
              md="4"
              class="px-5 py-0"
              :class="{
                'dark-divider': $vuetify.theme.dark && $vuetify.breakpoint.mdAndUp && index + 1 !== receiptsSummary[summary.key].insurance_summary.length,
                'light-divider': !$vuetify.theme.dark && $vuetify.breakpoint.mdAndUp && index + 1 !== receiptsSummary[summary.key].insurance_summary.length
              }"
            >
              <InsuranceBreakdowns :insurance="insurance" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCalendar,
  mdiPrinter,
  mdiMagnify,
  mdiClose,
  mdiFileDocumentOutline,
  mdiHelpCircleOutline,
  mdiContentSave,
  mdiPaperclip,
  mdiDownload,
  mdiOpenInNew,
} from '@mdi/js'
import {
  onUnmounted,
  onMounted,
  ref,
  inject,
  computed,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { Breakdowns, InsuranceBreakdowns } from '@/components/apps/summaries'
import { saveAs } from 'file-saver'
import ability from '@/plugins/acl/ability'
import receiptStoreModule from './receiptStoreModule'

export default {
  components: {
    Breakdowns,
    InsuranceBreakdowns,
  },
  setup() {
    // Initializers
    const RECEIPT_APP_STORE_MODULE_NAME = 'app-receipt'
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const can = inject('can')
    const { router } = useRouter()
    const isAuditor = ability.can('read', 'ReportSummaries')
    const format = isAuditor ? 'csv' : 'pdf'

    if (!store.hasModule(RECEIPT_APP_STORE_MODULE_NAME)) {
      store.registerModule(RECEIPT_APP_STORE_MODULE_NAME, receiptStoreModule)
    }

    // Table Handlers
    const tableColumns = computed(() => (
      [
        { text: t('Actions'), value: 'actions', align: can('write', 'ReportActions') ? 'center' : ' d-none' },
        { text: t('#Receipt No.'), value: 'receipt_number' },
        { text: t('Issue Date'), value: 'issued_at' },
        { text: t('Insurance Policy No.'), value: 'insurance_policy_number' },
        { text: t('Issuer'), value: 'issuer' },
        { text: t('Car Registration No.'), value: 'car_registration_number' },
        { text: t('Customer Name'), value: 'customer_name' },
        { text: t('Contact No.'), value: 'customer_contact_number' },
        { text: t('Insurance Premium'), align: 'right', value: 'insurance_premium' },
        { text: t('After Discount'), align: 'right', value: 'after_discount' },
        { text: t('Voucher Discount'), align: 'right', value: 'voucher_amount' },
        { text: t('Voucher Code'), align: 'right', value: 'voucher_code' },
        { text: t('Net Payment'), align: 'right', value: 'net_payment' },
        { text: t('SPD Reference No.'), align: 'right', value: 'full_spd_number' },
        { text: t('Road Tax'), align: 'right', value: 'road_tax' },
        { text: t('Additional Fees'), align: 'right', value: 'additional_fees' },
        { text: t('Payment Method'), value: 'payment_method' },
        { text: t('#Invoice No.'), value: 'invoice_number' },
        { text: t('General Remarks'), value: 'general_remarks' },
      ]
    ))
    const tableClerkColumns = computed(() => (
      [
        { text: t('Actions'), value: 'actions', align: 'center' },
        { text: t('#Receipt No.'), value: 'receipt_number' },
        { text: t('Issue Date'), value: 'issued_at' },
        { text: t('Insurance Policy No.'), value: 'insurance_policy_number' },
        { text: t('Car Registration No.'), value: 'car_registration_number' },
        { text: t('Contact No.'), value: 'customer_contact_number' },
        { text: t('Insurance Premium'), align: 'right', value: 'insurance_premium' },
        { text: t('After Discount'), align: 'right', value: 'after_discount' },
        { text: t('Voucher Discount'), align: 'right', value: 'voucher_amount' },
        { text: t('Voucher Code'), align: 'right', value: 'voucher_code' },
        { text: t('Net Payment'), align: 'right', value: 'net_payment' },
        { text: t('SPD Reference No.'), align: 'right', value: 'full_spd_number' },
        { text: t('Road Tax'), align: 'right', value: 'road_tax' },
        { text: t('Payment Method'), value: 'payment_method' },
        { text: t('#Invoice No.'), value: 'invoice_number' },
        { text: t('General Remarks'), value: 'general_remarks' },
      ]
    ))

    // Properties
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const receipts = ref([])
    const selectedReceipts = ref([])
    const excludedReceipts = ref([])
    const selectAll = ref(false)
    const receiptsSummary = ref({
      insurance_premium_summary: {},
      after_discount_summary: {},
      additional_fees_summary: {},
      net_payment_summary: {},
      profit_summary: {},
      road_tax_summary: {},
      voucher_discount_summary: {},
    })
    const tablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const searchText = ref('')
    const options = ref({
      sortBy: ['id'],
      sortDesc: [false],
    })
    const loading = ref(false)
    const loadingPrint = ref(false)
    const loadingDownload = ref(false)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const newComment = ref(null)
    const branchOptions = ref([
      { name: 'Ongoing branches', value: 'active' },
      { name: 'Closed branches', value: 'inactive' },
    ])
    const toggleSelected = ref('active')
    const branchFilters = ref([])
    const insuranceFilters = ref([])
    const insuranceCategoryOptions = ref([])
    const insuranceCategoryFilters = ref([])
    const accountFilters = ref([])
    const brokerFilters = ref([])
    const brokers = ref([])
    const corporateFilters = ref([])
    const corporates = ref([])
    const dateRange = ref([`${yearNow}-${monthNow}-${dayNow}`])
    const listLengthOptions = ref([20, 50, 100])
    const isDateMenuOpen = ref(false)
    const insuranceSummaryOptions = ref([
      { id: 1, title: 'After Discount', key: 'after_discount_summary' },
      { id: 2, title: 'Additional Fees', key: 'additional_fees_summary' },
      { id: 3, title: 'Road Tax', key: 'road_tax_summary' },
    ])
    const isVoidDialogVisible = ref(false)
    const voidableReceipt = ref({})
    const voidLoading = ref(false)
    const accounts = ref([])
    const pdfCount = ref(null)
    const createReceiptLimit = ref(null)
    const receiptScreenshot = ref({})
    const isScreenshotDialogVisible = ref(false)
    const paymentSelected = ref(null)
    const paymentFilters = ref([
      { id: 'BIBD', name: 'BIBD Bank' },
      { id: 'Baiduri', name: 'Baiduri Bank' },
      { id: 'Cash', name: 'Cash' },
      { id: 'Visa Card', name: 'Visa Card' },
    ])

    // Utils
    const formatDate = date => {
      if (!date) return null
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    }
    const checkNetPayment = (netPayment, afterDiscount) => {
      if (netPayment === null || afterDiscount === null) return false

      return Number(netPayment.replace(/[^0-9.-]+/g, '')) <= Number(afterDiscount.replace(/[^0-9.-]+/g, ''))
    }

    // Computed
    const branches = computed(() => {
      switch (toggleSelected.value) {
        case 'active':
          return store.state.branches.filter(b => b.active)
        case 'inactive':
          return store.state.branches.filter(b => b.active === false)
        default:
          return store.state.branches
      }
    })
    const insurances = computed(() => store.state.insurances)
    const dateRangeText = computed(() => {
      if (Date.parse(dateRange.value[0]) > Date.parse(dateRange.value[1])) {
        const [start, end] = dateRange.value
        dateRange.value[0] = end
        dateRange.value[1] = start
      }

      const startDateText = formatDate(dateRange.value[0])
      const showEndDate = dateRange.value[0] !== dateRange.value[1] && formatDate(dateRange.value[1]) !== null
      const endDateText = showEndDate ? ` - ${formatDate(dateRange.value[1])}` : ''

      return `${startDateText}${endDateText}`
    })

    // Methods
    const isLoading = () => {
      loading.value = true
      snackbarService.info('Loading your list. Please hold...')
    }
    const doneLoading = () => {
      loading.value = false
      snackbarService.success('Done loading your list!')
    }
    const fetchInsuranceCategories = () => {
      store
        .dispatch('app-receipt/fetchInsuranceCategories')
        .then(response => {
          const { data } = response.data
          insuranceCategoryOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurance categories. Please refresh!')
        })
    }
    const fetchReceipts = () => {
      store
        .dispatch('app-receipt/fetchReceipts', {
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
          search_text: searchText.value,
          user_ids: accountFilters.value.map(account => account.id),
          branch_ids: branchFilters.value.map(branch => branch.id),
          insurance_ids: insuranceFilters.value.map(insurance => insurance.id),
          broker_ids: brokerFilters.value.map(broker => broker.id),
          corporate_ids: corporateFilters.value.map(corporate => corporate.id),
          start_date: dateRange.value[0],
          end_date: dateRange.value[1] ? dateRange.value[1] : dateRange.value[0],
          payment_method_filter: paymentSelected.value,
          branch_toggle_filter: toggleSelected.value,
          insurance_category_ids: insuranceCategoryFilters.value.map(category => category.id),
        })
        .then(response => {
          const { data, pagination, summary } = response.data
          receipts.value = data
          receipts.value.forEach(field => {
            Object.keys(field).forEach(key => {
              if (key === 'net_payment') return
              if (field[key] === null) field[key] = 'NULL'
            })
          })
          if (selectAll.value) {
            receipts.value.forEach(receipt => {
              if (!excludedReceipts.value.includes(receipt.id)) selectedReceipts.value.push(receipt)
            })
          }
          tablePagination.value = pagination
          receiptsSummary.value = summary
          pdfCount.value = response.data.pdf_count
          createReceiptLimit.value = response.data.create_receipt_limit
          doneLoading()
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching receipts. Please refresh!')
        })
    }
    const pageChange = () => {
      isLoading()
      fetchReceipts()
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      isLoading()
      fetchReceipts()
    }
    const dateRangeChange = () => {
      isDateMenuOpen.value = false
      tablePagination.value.current_page = 1
      isLoading()
      fetchReceipts()
    }
    const activeToggleChange = () => {
      branchFilters.value = []
    }
    const valueChange = (id, value, type) => {
      if (value === null) return

      store
        .dispatch('app-receipt/updateValue', {
          id,
          receipt: { [type]: value },
          value_type: type,
        })
        .then(response => snackbarService.success(response.data.message))
        .catch(error => snackbarService.error(error.response.data.message || `Something went wrong while updating ${type} . Please refresh!`))
    }
    const printReport = (mode = '') => {
      loadingPrint.value = true
      const receiptIds = []
      selectedReceipts.value.forEach(receipt => {
        receiptIds.push(receipt.id)
      })
      store
        .dispatch('app-receipt/printReport', {
          search_text: searchText.value,
          user_ids: accountFilters.value.map(account => account.id),
          branch_ids: branchFilters.value.map(branch => branch.id),
          insurance_ids: insuranceFilters.value.map(insurance => insurance.id),
          broker_ids: brokerFilters.value.map(broker => broker.id),
          corporate_ids: corporateFilters.value.map(corporate => corporate.id),
          receipt_ids: receiptIds,
          excluded_receipt_ids: excludedReceipts.value,
          select_all: selectAll.value,
          start_date: dateRange.value[0],
          end_date: dateRange.value[1] ? dateRange.value[1] : dateRange.value[0],
          payment_method_filter: paymentSelected.value,
          branch_toggle_filter: toggleSelected.value,
          insurance_category_ids: insuranceCategoryFilters.value.map(category => category.id),
          mode,
          format,
        })
        .then(response => {
          if (response.type === 'application/json') {
            response.text().then(res => {
              const error = JSON.parse(res)
              snackbarService.error(`${error.message} ${error.receipt_ids || ''}`, -1)
            })
          } else {
            const url = window.URL.createObjectURL(response)
            const a = document.createElement('a')
            let filename = ''
            switch (mode) {
              case 'non_motor_report':
                filename = 'non-motor-'
                break
              case 'non_motor_and_broker_corporate_report':
                filename = 'non-motor-'
                break
              default:
                filename = 'full-'
            }
            a.href = url
            a.download = `${filename}receipt(${dateRangeText.value}).${format}`
            document.body.appendChild(a)
            a.click()
            a.remove()
          }
          loadingPrint.value = false
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while printing report. Please refresh!')
        })
    }
    const voidReceipt = () => {
      voidLoading.value = true
      store
        .dispatch('app-receipt/voidReceipt', { id: voidableReceipt.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchReceipts()
          isVoidDialogVisible.value = false
          voidLoading.value = false
        })
        .catch(error => {
          voidLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while voiding receipt. Please refresh!')
        })
    }
    const fetchClerks = () => {
      store
        .dispatch('app-receipt/fetchClerks', { role: [1, 4, 6] })
        .then(response => {
          const { data } = response.data
          accounts.value = data.filter(account => account.email !== 'test@niagamas.com')
        })
        .catch(error => snackbarService.error(error.response.data.message || 'Something went wrong while fetching accounts. Please refresh!'))
    }
    const fetchBrokers = () => {
      store
        .dispatch('customerStore/fetchBrokers')
        .then(response => {
          const { data } = response.data
          brokers.value = data
        })
        .catch(error => snackbarService.error(error.response.data.message || 'Something went wrong while fetching brokers. Please refresh!'))
    }
    const fetchCorporates = () => {
      store
        .dispatch('customerStore/fetchCorporates')
        .then(response => {
          const { data } = response.data
          corporates.value = data
        })
        .catch(error => snackbarService.error(error.response.data.message || 'Something went wrong while fetching corporates. Please refresh!'))
    }
    const createComment = id => {
      store
        .dispatch('requestStore/createComment', {
          user_id: userData.id,
          approval_request_id: id,
          comment: newComment.value,
        })
        .then(response => {
          snackbarService.success(response.data.message)
          router.push('/apps/requests/list')
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while saving comment. Please refresh!')
        })
    }
    const createRequest = () => {
      store
        .dispatch('requestStore/createRequest', {
          record_id: voidableReceipt.value.id,
          record_type: 'Receipt',
          requested_by_id: userData.id,
          action: 'Void',
        })
        .then(response => {
          if (newComment.value) {
            createComment(response.data.data.id)
          } else {
            snackbarService.success(response.data.message)
            router.push('/apps/requests/list')
          }
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while saving request. Please refresh!')
        })
    }
    const downloadPolicies = () => {
      loadingDownload.value = true
      const receiptIds = []
      selectedReceipts.value.forEach(receipt => {
        receiptIds.push(receipt.id)
      })
      store
        .dispatch('app-receipt/downloadPolicies', {
          search_text: searchText.value,
          user_ids: accountFilters.value.map(account => account.id),
          branch_ids: branchFilters.value.map(branch => branch.id),
          insurance_ids: insuranceFilters.value.map(insurance => insurance.id),
          broker_ids: brokerFilters.value.map(broker => broker.id),
          corporate_ids: corporateFilters.value.map(corporate => corporate.id),
          receipt_ids: receiptIds,
          excluded_receipt_ids: excludedReceipts.value,
          select_all: selectAll.value,
          start_date: dateRange.value[0],
          end_date: dateRange.value[1] ? dateRange.value[1] : dateRange.value[0],
          payment_method_filter: paymentSelected.value,
          branch_toggle_filter: toggleSelected.value,
          insurance_category_ids: insuranceCategoryFilters.value.map(category => category.id),
        })
        .then(response => {
          snackbarService.success(`Downloaded ${pdfCount.value} insurance policies!`)
          const fileURL = window.URL.createObjectURL(response)
          saveAs(fileURL, `policies(${dateRangeText.value}).zip`)
          loadingDownload.value = false
        })
        .catch(() => {
          loadingDownload.value = false
          snackbarService.error('Something went wrong while downloading policies. Please refresh!')
        })
    }
    const downloadScreenshot = receipt => {
      fetch(receipt.payment_screenshot_url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `${receipt.receipt_number}_${receipt.issued_at.split('/').join('-')}_${receipt.payment_screenshot_name}`
          link.click()
        })
        .catch(() => {
          snackbarService.error('Something went wrong while downloading screenshot. Please refresh!')
        })
    }
    const selectedChange = event => {
      if (selectAll.value) {
        if (!event.value) {
          excludedReceipts.value.push(event.item.id)
        } else {
          const index = excludedReceipts.value.indexOf(event.item.id)
          if (index > -1) excludedReceipts.value.splice(index, 1)
        }
      }
    }
    const toggleAll = event => {
      if (!event.value) {
        selectedReceipts.value = []
        excludedReceipts.value = []
        selectAll.value = false
      } else {
        excludedReceipts.value = []
        selectAll.value = true
      }
    }
    const searchReceipts = () => {
      isLoading()
      fetchReceipts()
    }

    onMounted(() => {
      fetchInsuranceCategories()
      fetchClerks()
      fetchBrokers()
      fetchCorporates()
      searchReceipts()
    })

    // Unmounted
    onUnmounted(() => {
      if (store.hasModule(RECEIPT_APP_STORE_MODULE_NAME)) store.unregisterModule(RECEIPT_APP_STORE_MODULE_NAME)
    })

    return {
      t,
      can,
      loading,
      loadingPrint,
      receipts,
      selectedReceipts,
      accounts,
      branches,
      insurances,
      brokers,
      corporates,
      tablePagination,
      receiptsSummary,
      tableColumns,
      tableClerkColumns,
      isDateMenuOpen,
      listLengthOptions,
      options,
      insuranceSummaryOptions,
      searchText,
      branchOptions,
      branchFilters,
      insuranceFilters,
      insuranceCategoryFilters,
      insuranceCategoryOptions,
      accountFilters,
      brokerFilters,
      corporateFilters,
      dateRange,
      dateRangeText,
      fetchReceipts,
      pageChange,
      listLengthChange,
      dateRangeChange,
      activeToggleChange,
      formatDate,
      valueChange,
      printReport,
      isVoidDialogVisible,
      voidableReceipt,
      voidLoading,
      voidReceipt,
      checkNetPayment,
      createRequest,
      newComment,
      downloadPolicies,
      loadingDownload,
      pdfCount,
      createReceiptLimit,
      receiptScreenshot,
      isScreenshotDialogVisible,
      downloadScreenshot,
      selectedChange,
      toggleAll,
      searchReceipts,
      paymentSelected,
      paymentFilters,
      toggleSelected,

      icons: {
        mdiPlus,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPrinter,
        mdiMagnify,
        mdiClose,
        mdiContentSave,
        mdiFileDocumentOutline,
        mdiHelpCircleOutline,
        mdiPaperclip,
        mdiDownload,
        mdiOpenInNew,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  #receipt-list {
    .receipt-list-search {
      width: 32.5rem;
    }

    .receipt-list-insurances {
      max-width: 33rem;
    }

    .receipt-list-active {
      max-width: 33rem;
    }

    .receipt-list-branch {
      max-width: 33rem;
    }

    .receipt-list-account {
      max-width: 33rem;
    }

    .receipt-list-row-selection {
      max-width: 5.3rem;
    }

    .receipt-payment {
      min-width: 10rem;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      .receipt-payment {
        width: 100px;
      }
    }
  }

  .receipt-button {
    width: 251px;
  }

  .payment-screenshot-viewer {
    height: 80vh;
    width: 100%;
  }

  ::v-deep .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg.loading-color {
    background: #d3d3d3 !important;
    color: #eee !important;
  }

  .dark-divider {
    border-right: 1px solid rgba(231, 227, 252, 0.14) !important;
  }

  .light-divider {
    border-right: 1px solid rgba(94, 86, 105, 0.14);
  }

  .create-receipt-hint {
    max-width: 350px;
    padding-bottom: 22px;
  }
</style>
