<template>
  <v-row class="app-preview">
    <v-col
      cols="12"
      md="9"
    >
      <v-card class="app-loyalty-editable">
        <!-- Header -->
        <div class="py-5 px-5">
          <div class="d-flex flex-wrap justify-space-between">
            <!-- Date -->
            <div class="d-flex align-center">
              <span class="me-2 font-weight-medium text--primary">Create Niagamas Loyalty</span>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Customer Details -->
        <div class="py-5 px-5">
          <div class="mb-3 d-flex">
            <div class="px-3 flex-grow-1">
              <v-row>
                <!-- Registration Date -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold payment-details-header">
                    Registration Date:
                  </p>
                  <v-menu
                    v-model="isDateMenuOpen"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :append-icon="icons.mdiCalendar"
                        :value="new Date(loyalty.registration_date).toLocaleDateString()"
                        readonly
                        dense
                        outlined
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="loyalty.registration_date"
                      color="primary"
                      :first-day-of-week="1"
                      @input="isDateMenuOpen = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <!-- Loyalty Type -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pr-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold payment-details-header">
                    Loyalty Type:
                  </p>
                  <v-select
                    v-model="loyalty.loyalty_type"
                    :items="loyaltyTypeOptions"
                    dense
                    outlined
                    hide-details="auto"
                    placeholder="Select Loyalty Type"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <!-- Name -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                  class="offset-sm-0"
                >
                  <p class="font-weight-semibold payment-details-header">
                    Customer Name:
                  </p>
                  <v-text-field
                    v-model="loyalty.name"
                    single-line
                    dense
                    outlined
                    hide-details
                    placeholder="Customer Name"
                  ></v-text-field>
                </v-col>

                <!-- Contact Number -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pr-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold payment-details-header">
                    Contact No.:
                  </p>
                  <v-text-field
                    v-model="loyalty.contact_number"
                    single-line
                    dense
                    outlined
                    type="number"
                    :rules="[v => /[0-9\-().\s]{7}$/.test(v) || 'Must be valid contact number']"
                    hide-details="auto"
                    placeholder="Contact Number"
                    prefix="+673"
                  ></v-text-field>
                </v-col>

                <!-- IC Number -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold payment-details-header">
                    IC No.:
                  </p>
                  <v-text-field
                    v-model="loyalty.ic_number"
                    single-line
                    dense
                    outlined
                    hide-details="auto"
                    placeholder="IC Number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-card class="mb-6">
        <v-card-text>
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="createLoyalty()"
          >
            {{ t('Confirm Loyalty') }}
          </v-btn>

          <div class="text-center mt-4">
            {{ t('Please ensure that all fields are filled correctly as this record will not be editable after confirming.') }}
          </div>
        </v-card-text>
      </v-card>
      <v-card
        v-if="errors.length != 0"
        class="mb-6"
      >
        <v-card-text>
          <Alert
            :messages="errors"
            color="error"
          ></Alert>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiCalendar, mdiPaperclip } from '@mdi/js'
import {
  inject,
  onMounted,
  ref,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import Alert from '@/components/Alert'

export default {
  components: {
    Alert,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const { router } = useRouter()

    // Properties
    const loyalty = ref({
      registration_date: new Date().toISOString().substring(0, 10),
      name: null,
      contact_number: null,
      ic_number: null,
      loyalty_type: null,
    })
    const loyaltyTypeOptions = ref([])
    const errors = ref([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    const dateToday = new Date().toLocaleDateString('en-SG')
    const loading = ref(false)
    const isDateMenuOpen = ref(false)

    // Methods
    const createLoyalty = () => {
      loading.value = true
      errors.value = []
      store
        .dispatch('customerStore/createNiagamasLoyalty', loyalty.value)
        .then(response => {
          snackbarService.success(response.data.message)
          router.push('/apps/profile-creation/list')
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while saving niagamas loyalty. Please refresh!')
        })
    }
    const isNumber = event => {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      const keyPressed = event.key
      if (!keysAllowed.includes(keyPressed)) {
        event.preventDefault()
      }
    }
    const fetchLoyaltyTypes = () => {
      store
        .dispatch('customerStore/fetchLoyaltyTypes')
        .then(response => {
          const { data } = response.data
          loyaltyTypeOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching loyalty types. Please refresh!')
        })
    }

    // Mounted
    onMounted(() => {
      fetchLoyaltyTypes()
    })

    return {
      t,
      loyalty,
      errors,
      createLoyalty,
      userData,
      dateToday,
      loading,
      isNumber,
      isDateMenuOpen,
      loyaltyTypeOptions,

      icons: {
        mdiCalendar,
        mdiPaperclip,
      },
    }
  },
}
</script>
